<template>
  <v-app fill-height>
    <div class="row flex-column h-100">
      <div class="d-flex flex-column align-items-center mt-5 mb-10">
        <div class="d-flex align-items-center">
          <span class="f13" :class="!this.$vuetify.rtl ? 'visible' : 'invisible'">{{$t('RIAL')}}</span>
          <span class="mx-2 f24 font-weight-bold">{{ accountBalance.cash | VMask(currencyMask) }}</span>
          <span class="f13" :class="this.$vuetify.rtl ? 'visible' : 'invisible'">{{$t('RIAL')}}</span>
        </div>
        <span class="f14 mt-4">
          {{ $t('Your balance') }}
        </span>
        <v-btn color="primary" large class="mt-4 rounded-xl" @click.prevent="settlementRequest">
          {{ $t('Request for settlement') }}
        </v-btn>
      </div>

      <div class="bg-white d-flex flex-column grow rounded-t-xl text-dark p-4">
        <span class="f15 fw-bold">{{$t('Transactions')}}</span>

        <div v-if="accountBalance.details && accountBalance.details.length > 0">
          <v-card class="my-2 border" v-for="(item, index) in accountBalance.details" :key="index" flat hover ripple light>
            
            <div class="badge-promo mb-1" v-if="item.status == 'DEPOSIT' && item.isSettled == true">
              <span class="badge-promo-content f11">
                {{ $t('Settled') }}
              </span>
            </div>
            
            <div class="badge-promo mb-1" v-if="item.status == 'DEPOSIT' && item.isSettled == false && item.settlementId">
              <span class="badge-promo-content f11">
                {{ $t('Awaiting settlement') }}
              </span>
            </div>
            <b-list-group-item class="d-flex align-items-center rounded py-2 ps-2 pe-3 border-none">
              <v-avatar v-if="item.status == 'DEPOSIT'" color="green lighten-5 p-2" size="48">
                <iconly type="outline" name="arrow-up" class="green--text"/>
              </v-avatar>
              <v-avatar v-if="item.status == 'WITHDRAW'" color="red lighten-5 p-2" size="48">
                <iconly type="outline" name="arrow-down" class="red--text"/>
              </v-avatar>


              <div class="d-flex flex-column flex-fill ms-2 me-0">
                <div class="d-flex flex-row">
                  <div class="d-flex flex-row align-items-start justify-content-center">
                    <span class="f13" v-if="item.reason == 'CHARGE_WALLET'">{{ $t('Wallet charge') }}</span>

                    <span class="f13 ms-0 me-2" v-if="item.reason == 'EXAM' && item.clientFullName">{{ item.clientFullName }}</span>
                    <span class="f11 primary lighten-5 rounded-pill px-2 py-0 primary--text" v-if="item.reason == 'EXAM'">{{ $t('Exam') }}</span>

                    <span class="f13 ms-0 me-2" v-if="item.reason == 'CONSULTANT' && item.clientFullName">{{ item.clientFullName }}</span>
                    <span class="f11 primary lighten-5 rounded-pill px-2 py-0 primary--text" v-if="item.reason == 'CONSULTANT'">{{ $t('Consultant') }}</span>
                  </div>

                  <div class="box d-flex ms-auto me-0 align-items-end justify-content-center">
                    <span class="f13 red--text" v-if="item.status == 'WITHDRAW'">- {{ item.amount | VMask(currencyMask) }} {{ $t('RIAL') }}</span>
                    <span class="f13 green--text" v-if="item.status == 'DEPOSIT'">+ {{ item.amount | VMask(currencyMask) }} {{ $t('RIAL') }}</span>
                  </div>
                </div>
                
                <span class="f11 grey--text d-flex flex-row align-items-center mt-1">
                <i class="far fa-calendar-alt ms-0 me-1"/>
                {{ item.createDate | moment("dddd , jD jMMMM jYYYY") }} 
                <i class="far fa-clock ms-2 me-1"/>
                {{ item.createDate | moment("HH:MM") }} 
                </span>
              </div>
            </b-list-group-item>
            
          </v-card>
        </div>
        <span class="text-center f16 d-block mt-8" v-else>
          {{$t('No results found')}}
        </span>
      </div>
    </div>
  </v-app>
</template>

<script>
import apiService from "@/http/ApiService";
import moment from "moment-jalaali";
import fa from "moment/locale/fa";
moment.locale("fa", fa);
moment.loadPersian();

import createNumberMask from 'text-mask-addons/dist/createNumberMask';
  const currencyMask = createNumberMask({
    prefix: '',
    allowDecimal: false,
    includeThousandsSeparator: true,
    allowNegative: false,
  });

export default {
  data() {
    return {
      currencyMask,

      accountBalance: {},
    }
  },
  methods: {
    getDoctorAccountBalance() {
      apiService.getDoctorAccountBalance()
        .then((response) => {
          this.accountBalance = response.data;         
        })
        .catch((err) => {
          console.log(err);
          this.$swal({
            icon: "warning",
            text: err ? err.response.data.detail : this.$t("Error"),
            confirmButtonText: this.$t('OK'),
          });
        });
    },
    settlementRequest(){
      if (this.accountBalance.cash > 0) {
        apiService.settlementRequest
        .then(() => {
          this.$swal({
            icon: "success",
            text: this.$t("Settlement request has been successfully sent"),
            confirmButtonText: this.$t('OK'),
          });
        })
        .catch(() => {
          this.$swal({
            icon: "error",
            text: this.$t("Unfortunately, sending the settlement request was unsuccessful. Please try again or contact support"),
            confirmButtonText: this.$t('OK'),
          });
        });
      }
      else {
        this.$swal({
          icon: "warning",
          text: this.$t("Your account balance is not enough to request a settlement"),
          confirmButtonText: this.$t('OK'),
        });
      }
    },
  },
  beforeMount() {
    this.$store.commit('update_backActive', true);
    this.$store.commit('update_helloActive', false);
    this.$store.commit('update_threeDotsActive', false);
    this.$store.commit('update_notificationActive', true);
    this.$store.commit('update_toolbarTitleActive', true);
  },
  beforeDestroy() {
    console.log("beforeDestroy");
    this.$vuetify.theme.dark = false;
  },
  mounted() {
    this.$vuetify.theme.dark = true;
    this.getDoctorAccountBalance();
  }
}
</script>

<style scoped lang="scss">
// ribbon code
.badge-promo {
  position: relative;
  overflow: hidden;
  max-height: 60px;
  margin-bottom: -8px;
  z-index: 1;
  padding: 0 8px;
  border-radius: 2px 2px 0 0;
  color: #fff;
  text-align: left;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
  }

  &:before {
    background: #a5daff;
    height: 100%;
    width: 110%;
    transform-origin: bottom right;
    transform: rotate(1deg);
  }

  &:after {
    background: #3fb0ff;
    transform: rotate(-3deg);
    transform-origin: bottom left;
    height: 100%;
    width: 110%;
  }
}

.badge-promo-content {
  position: relative;
  z-index: 1;
}
</style>